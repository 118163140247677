.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
}
.enrollBtn {
    width: 60%;

    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #000;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
    margin-bottom: 10px;
}

.infoDetail {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
}