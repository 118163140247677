.courseContentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
overflow: hidden;
    justify-content: center;
    align-items: center;
}
.contentFormat {
    line-height: 1px;
    margin-bottom: 2px;
}