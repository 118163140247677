.courseCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 320px;
    height: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
  }
  .courseCard:hover {
    transform: translateY(-0.5rem);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }
  .courseCard__img {
    width: 100%;
    height: 10rem;
    object-fit: cover;
    overflow: hidden;
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .courseCard__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 1rem;
  }
  .courseCard__title_link:hover {
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: underline;
  }
  .courseCard__description {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 1rem;
  
  }
  .courseCard__price {
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .courseCard__button {
    display: inline-block;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #000;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
  }
  .courseCard__share{
   display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .courseCard__button:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
  }
  .courseCard_footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  
  }  