.courses_container {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.title {
    display: flex;
    flex-wrap: wrap;
    font-size: larger;
    justify-content: center;
    margin-bottom: 10px;
}