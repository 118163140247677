.container {
  width: 100%;
display: flex;
align-items: center;
justify-content: center;
}
.testimonialContent h2 {
    font-weight: 600;
  
  }
  .testimonialContent {
    flex:1;
    width: 50vw;
  
  }
  .testimonialWrapper{
    display: flex;

    align-items: center;
    justify-content: center;


 
  }
  .testimonialImg {
  
  flex: 1;
  }
  .testimonialImg img {

    }

  .btns{
    background: #000000;
    color: #fff;
    padding: 7px 25px;
    border-radius: 50px;
  }
  
  .btns:hover {
    background-color: #444343;
  }
  @media only screen and (max-width: 900px) {
    .testimonialWrapper {
      display: flex;
      flex-direction: column;
    }
  }