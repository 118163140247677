.registerForm {
  display: flex;
  justify-content: center;
  align-items: center;
}
.register_registerForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
    padding: 20px;
}

.register_input {
    width: 100%;
    height: 30px;
    border-radius: 10px;
    border: 1px solid rgb(172, 171, 171);
    padding: 5px;
    font-size: 1rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .register_submit {
    margin-top: 10px;
  width: 100%;
    padding: 10px;
    border-radius: 10px;
    color: white;
    font-size: 1.2rem;
    background-color: black;
  }
  .register_submit:disabled {
    cursor: not-allowed;
    background-color: rgb(148, 146, 146);
  }

  .register_customSelect_input {
    width: 100%;
  }

  .register_error{
    color: red;
  }
  .register_dataSubmitted {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .register_terms_link {
    color: blue;
  }

