.header {
  width: 100%;
  height: 80px;
  /* line-height: 80px; */
  /* background-color: rgb(199, 116, 7); */
  display: flex;
}

.headerItems {
  display: flex;
  gap: 1.6rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
}

.searchTextBox {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0 1rem;
  border-bottom: 1px solid black;
}
.headerSmallScreen {
  display: none;
}

.languageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.languageContainerItems {
  font-size: 1.2rem;
  cursor: pointer;
}
@media only screen and (max-width: 400px) {
  .headerSmallScreen {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .headerLargeScreen {
    display: none;
  }
}
