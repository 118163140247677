.container {
  margin: 10px 20px;
}
.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}
.singleCourseItem {
  padding: 15px;
}
.courseCards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.courseImg img {
  border-radius: 10px;
  margin-bottom: 20px;
}

.enroll a {
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  color: #17bf9e;
}

.lesson,
.students,
.rating {
  font-size: 0.9rem;
  color: #0a2b1e;
  font-weight: 500;
}

.lesson i,
.students i,
.rating i {
  font-weight: 400;
}

.courseTitle {
  line-height: 2rem;
}

.courseTopLeft h2 {
  font-weight: 600;
}
.courseTopLeft p {
  width: 50%;
}
.courseTopLeft {
  display: flex;
  justify-content: space-between;
}

.btns {
  background: #000000;
  color: #fff;
  padding: 7px 25px;
  border-radius: 50px;
}

.btns:hover {
  background-color: #444343;
}

@media only screen and (max-width: 400px) {
  .courseTopLeft {
    display: flex;
    flex-direction: column;
  }
}