.footer {
  margin: 30px;
    background: white
  }
  
  .socialMedia {
    font-size: 2rem;
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    flex-wrap: wrap;
    border-top: 1px solid rgba(0,0,0,0.3);
  }
  
  .socialMedia  i {
    color: rgb(112, 110, 110);
    
  }
  
  .socialMedia  i:hover, .socialMedia  img:hover{
    color: black;
    cursor: pointer;
  }