.container {
    padding:20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 30px;
}
.title {
    font-size: 30px;
    font-weight: 600;
    color: #000;
    text-align: center;
    margin-bottom: "30px";
}
.items {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;

}
.leftItem {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex:3;
  
}
.whatULearn {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
  }
  .whatULearn:hover {
    transform: translateY(-0.5rem);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5);
  }
.priceDetails {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding:10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
  }
  .priceDetails:hover {
    transform: translateY(-0.5rem);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5);
  }
.rightItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
border-radius: 10px;
    gap: 10px;
    flex:5;
    padding: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    transition: all 0.2s;
  }
  .rightItem:hover {
    transform: translateY(-0.5rem);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.8);
  }

@media screen and (max-width: 900px) {
    .items {
        flex-direction: column;
    }
    .leftItem {
        flex: 1;
    }
    .rightItem {
        flex: 1;
    }
}
